import { z } from 'zod'
import { VendorsEnum } from './common'

export const LocationSearchParamsSchema = z.object({
  partner: z.string(),
  searchType: z.string(),
  searchString: z.string(),
  vendorBrandId: z.string().optional(),
  sortHotels: z.string().optional(),
})

export const OpeningTimesSchema = z.object({
  day: z.string(),
  hours: z.array(z.string()),
})

export const LocationSchema = z.object({
  id: z.string(),
  vendorBrandId: VendorsEnum.optional(),
  type: z.string(),
  name: z.string(),
  fullName: z.string(),
  countryCode: z.string(),
})

const partnerIdRegex = /^[A-Z]{2}$/
const airportCodeRegex = /^[A-Z]{3}$/
export const GetAirportsParamsSchema = z.object({
  searchString: z.string(),
  fromAirport: z.string().optional(),
  partnerIds: z
    .string()
    .refine((value) => value === undefined || new RegExp(partnerIdRegex).test(value), {
      message: 'Invalid partnerId',
    }),
})
export const GetAirportSchema = z.object({
  airportCode: z.string().refine((value) => new RegExp(airportCodeRegex).test(value), {
    message: 'Invalid airportCode',
  }),
})

export const LocationDataTransferObjectsSchema = z.array(LocationSchema)

export const CarHireLocationSearchSchema = z.object({
  id: z.string(),
  type: z.string(),
  name: z.string(),
  fullName: z.string(),
  countryCode: z.string(),
  address: z.string(),
  emailAddress: z.string().optional(),
  afterHoursIndicator: z.union([z.boolean(), z.string()]).optional(),
  telephoneNumber: z.string().optional(),
  location: z
    .array(z.object({ latitude: z.string().optional(), longitude: z.string().optional() }))
    .optional(),
  openingTimes: z.array(OpeningTimesSchema).optional(),
  brandId: VendorsEnum,
})

export const CarHireLocationSearchDataTransferObjectSchema = CarHireLocationSearchSchema.omit({
  address: true,
  brandId: true,
}).extend({
  address: z.array(z.string()),
  vendorBrandId: VendorsEnum,
})

export const CarHireLocationSearchParamsSchema = CarHireLocationSearchSchema.omit({
  address: true,
  afterHoursIndicator: true,
  location: true,
  openingTimes: true,
}).extend({
  address: z.string(),
  afterHoursIndicator: z.string(),
  location: z.string(),
  openingTimes: z.string(),
})

export const CarHireLocationSearchDataTransferObjectsSchema = z.array(
  CarHireLocationSearchDataTransferObjectSchema
)

export const DestinationSchema = z.object({
  id: z.string().min(1),
  type: z.string().min(1),
  fullName: z.string().min(1),
  name: z.string().min(1).optional(),
})

export type Location = z.infer<typeof LocationSchema>
export type Destination = z.infer<typeof DestinationSchema>
export type OpeningTimes = z.infer<typeof OpeningTimesSchema>
export type CarHireLocationSearch = z.infer<typeof CarHireLocationSearchSchema>
export type CarHireLocationSearchDataTransferObject = z.infer<
  typeof CarHireLocationSearchDataTransferObjectSchema
>
export type CarHireLocationSearchDataTransferObjects = z.infer<
  typeof CarHireLocationSearchDataTransferObjectsSchema
>
export type LocationDataTransferObjects = z.infer<typeof LocationDataTransferObjectsSchema>
export type LocationSearchParams = z.infer<typeof LocationSearchParamsSchema>
