import { z } from 'zod'

import { CountryCodesSchema, CurrencyCodesSchema } from '../../country-currency-codes'

export const MemberDataTransferObjectSchema = z.object({
  enrolmentDate: z.string().optional(),
  termsAndConditions: z
    .object({
      version: z.string().optional(),
      acceptedDate: z.string(),
    })
    .optional(),
  memberIdentifier: z
    .object({
      identifier: z.string(),
    })
    .optional(),
  person: z.object({
    personName: z.object({
      title: z.string().optional(),
      firstName: z.string(),
      familyName: z.string(),
    }),
    dateOfBirth: z.string().optional(),
    gender: z.string().optional(),
    locale: z.string().optional(),
    nationality: z
      .object({
        type: z.string(),
        identifier: z.string().optional(),
      })
      .optional(),
    countryOfResidence: z.object({
      type: z.string(),
      identifier: CountryCodesSchema,
    }),
    postalAddresses: z
      .array(
        z.object({
          preferenceIndicator: z.boolean().optional(),
          type: z.string().optional(),
          placeType: z.string().optional(),
          addressLines: z.array(z.string().nullable()).optional(),
          postCode: z.string().optional(),
          country: CountryCodesSchema.optional(),
          city: z.string().optional(),
          shouldValidate: z.boolean().optional(),
          isMemberAvailable: z.boolean().optional(),
        })
      )
      .optional(),
    telecomAddresses: z
      .array(
        z.object({
          deviceType: z.string().optional(),
          type: z.string().optional(),
          preferenceIndicator: z.boolean().optional(),
          number: z.string().optional(),
          countryDiallingCode: z.string().optional(),
        })
      )
      .optional(),
    emailAddress: z
      .object({
        type: z.string().optional(),
        email: z.string().optional(),
        isValid: z.boolean().optional(),
        mailBoxType: z.string().optional(),
      })
      .optional(),
    currencyCode: CurrencyCodesSchema,
  }),
  status: z
    .object({
      code: z.string(),
    })
    .optional(),
  lastModified: z.string().optional(),
  otherStatuses: z
    .object({
      isLoanEnabled: z.boolean().optional(),
      isEligibleForRewardFlightSaver: z.boolean().optional(),
      isEmailValidated: z.boolean().optional(),
      isRedemptionEnabled: z.boolean().optional(),
    })
    .optional(),
  profileStatus: z.string().optional(),
  links: z
    .array(
      z.object({
        rel: z.string(),
        href: z.string(),
      })
    )
    .optional(),
})

export type MemberDataTransferObject = z.infer<typeof MemberDataTransferObjectSchema>
