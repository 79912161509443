import { z } from 'zod'
import { CurrencyCodesSchema } from '../../country-currency-codes'
import { PaginationSchema, PriceLadderSchema, DurationSchema } from '../../common'

export const ExperienceSearchResult = z.object({
  bundleId: z.string(),
  productId: z.string().optional(),
  vendorName: z.string().optional(),
  name: z.string(),
  description: z.string(),
  duration: DurationSchema.optional(),
  cashPrice: z.number(),
  collectAmount: z.number(),
  aviosPrice: z.number(),
  pricingLadder: z.array(PriceLadderSchema).optional(),
  imageUrl: z.string().optional(),
  guestRating: z.number().optional(),
  guestRatingCount: z.number().optional(),
  currencyCode: CurrencyCodesSchema,
})

export const ExperienceSearchDataTransferObjectSchema = z.object({
  correlationId: z.string(),
  recommendationId: z.string(),
  results: z.array(ExperienceSearchResult),
  // TODO: pagination should not be optional
  pagination: PaginationSchema.optional(),
})

export type ExperienceSearchOffer = z.infer<typeof ExperienceSearchResult>
export type ExperienceSearchDataTransferObject = z.infer<
  typeof ExperienceSearchDataTransferObjectSchema
>
