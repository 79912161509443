import { z } from 'zod'

export const AddressSchema = z
  .object({
    cityName: z.string(),
    countryCode: z.string(),
    stateOrProvince: z.string(),
    streetAddress: z.array(z.string()),
    zipCode: z.string(),
  })
  .partial()
