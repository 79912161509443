import { IntlShape } from 'react-intl'
import { z, ZodIssueCode } from 'zod'
import { MembershipSchema } from '../members'

export enum TravelerType {
  senior = 'senior',
  adult = 'adult',
  youth = 'youth',
  child = 'child',
  infant = 'infant',
  traveler = 'traveler',
}

type ExperienceParticipantsSchemaParams = {
  [key in TravelerType]?: {
    min?: number
    max?: number
  }
}

export const createExperienceParticipantsSchema = ({
  senior,
  adult,
  youth,
  child,
  infant,
  traveler,
}: ExperienceParticipantsSchemaParams) =>
  z.object({
    [TravelerType.senior]: z
      .number()
      .min(senior?.min ?? 0)
      .max(senior?.max ?? Number.MAX_SAFE_INTEGER)
      .default(0),
    [TravelerType.adult]: z
      .number()
      .min(adult?.min ?? 0)
      .max(adult?.max ?? Number.MAX_SAFE_INTEGER)
      .default(0),
    [TravelerType.youth]: z
      .number()
      .min(youth?.min ?? 0)
      .max(youth?.max ?? Number.MAX_SAFE_INTEGER)
      .default(0),
    [TravelerType.child]: z
      .number()
      .min(child?.min ?? 0)
      .max(child?.max ?? Number.MAX_SAFE_INTEGER)
      .default(0),
    [TravelerType.infant]: z
      .number()
      .min(infant?.min ?? 0)
      .max(infant?.max ?? Number.MAX_SAFE_INTEGER)
      .default(0),
    [TravelerType.traveler]: z
      .number()
      .min(traveler?.min ?? 0)
      .max(traveler?.max ?? Number.MAX_SAFE_INTEGER)
      .default(0),
  })

export type ExperienceParticipants = z.infer<ReturnType<typeof createExperienceParticipantsSchema>>

export interface ExperienceDetailsSchemaParams {
  participantsLimits: ExperienceParticipantsSchemaParams
  maxNumberOfParticipants: number
  minNumberOfParticipants: number
  intl: IntlShape
}

export const createExperienceDetailsSchema = ({
  participantsLimits,
  maxNumberOfParticipants,
  minNumberOfParticipants,
  intl,
}: ExperienceDetailsSchemaParams) =>
  z.object({
    id: z.string().min(1).optional(),
    date: z.date(),
    participants: createExperienceParticipantsSchema(participantsLimits)
      .refine(
        (participants) => Object.values(participants).find((value) => value > 0) !== undefined,
        {
          message: intl.formatMessage({
            id: 'no-travelers',
            defaultMessage: 'Please select a number of travellers',
          }),
        }
      )
      .refine(
        (participants) => {
          const totalTravelers = Object.values(participants).reduce(
            (previousValue, currentValue) => previousValue + currentValue
          )

          return totalTravelers >= minNumberOfParticipants
        },
        {
          message: intl.formatMessage(
            {
              id: 'min-travelers',
              defaultMessage:
                'A minimum of {minNumberOfParticipants} travellers is required for this trip',
            },
            { minNumberOfParticipants }
          ),
        }
      )
      .superRefine((ps, ctx) => {
        const totalParticipants = Object.values(ps).reduce((acc, count) => acc + count, 0)
        if (totalParticipants > maxNumberOfParticipants) {
          ctx.addIssue({
            code: ZodIssueCode.too_big,
            message: intl.formatMessage(
              {
                id: 'min-travelers',
                defaultMessage:
                  'Too many participants, {totalParticipants} > max {maxNumberOfParticipants}',
              },
              { totalParticipants, maxNumberOfParticipants }
            ),
            inclusive: true,
            type: 'number',
            maximum: maxNumberOfParticipants,
          })
        }
      }),
  })

export type ExperienceDetails = z.infer<ReturnType<typeof createExperienceDetailsSchema>>

const TravelerSchema = z.object({
  ageGroup: z.string(),
  roomGroup: z.number().optional(),
  age: z.number().optional(),
  dateOfBirth: z.string().optional(),
})

export const ExperienceDetailsParamsSchema = z.object({
  bundleId: z.string().min(1),
  recommendationId: z.string().min(1),
  correlationId: z.string().min(1),
  startDateTime: z.string().min(1),
  membership: MembershipSchema.optional(),
})

export type ExperienceDetailsParams = z.infer<typeof ExperienceDetailsParamsSchema>

export const ExperienceAvailabilityParamsSchema = z.object({
  bundleId: z.string().min(1),
  recommendationId: z.string().min(1),
  correlationId: z.string().min(1),
  startDateTime: z.string().min(1),
  adult: z.string().max(2).optional(),
  child: z.string().max(2).optional(),
  infant: z.string().max(2).optional(),
  senior: z.string().max(2).optional(),
  youth: z.string().max(2).optional(),
  traveler: z.string().max(2).optional(),
  membership: MembershipSchema.optional(),
})

export type ExperienceAvailabilityParams = z.infer<typeof ExperienceAvailabilityParamsSchema>
export type Traveler = z.infer<typeof TravelerSchema>
