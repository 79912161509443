import { useMemo } from 'react'
import { ONE_DAY } from '~/utils/datetime'
import {
  ExperienceForm,
  ExperienceSearchParams,
} from '@reward-platform/ancillaries-schemas/experience'

const DEFAULT_DESTINATION_STATE = {
  type: '',
  id: '',
  fullName: '',
}

const setEndDateTime = (endDateTime: string | undefined, startDateTime: Date) => {
  if (!endDateTime) {
    return new Date(startDateTime.getTime() + ONE_DAY)
  }

  return new Date(endDateTime)
}

export const useQueryStateToFormState = (queryState: ExperienceSearchParams): ExperienceForm => {
  const { destination = DEFAULT_DESTINATION_STATE, dates } = queryState
  return useMemo(() => {
    const minDate = new Date(Date.now() + ONE_DAY * 3)
    const startDateTime = dates?.startDateTime ? new Date(dates.startDateTime) : minDate
    const endDateTime = setEndDateTime(dates?.endDateTime, startDateTime)

    return {
      searchString: destination?.fullName || '',
      startDateTime,
      endDateTime,
      destination,
    }
  }, [destination, dates])
}
