import { z } from 'zod'

type LangTimeRequired = {
  isLanguageRequired: boolean
  isTimeRequired: boolean
}

export const createExperienceDetailsSearchResultSchema = ({
  isLanguageRequired,
  isTimeRequired,
}: LangTimeRequired) =>
  z.object({
    id: z.string().min(1),
    language: isLanguageRequired ? z.string().min(1) : z.string().optional(),
    time: isTimeRequired ? z.string().min(1) : z.string().optional(),
  })

export type ExperienceDetailsSearchResult = z.infer<
  ReturnType<typeof createExperienceDetailsSearchResultSchema>
>
