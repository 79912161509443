import { z } from 'zod'
import { DestinationSchema } from '../location'
import { MembershipSchema } from '../members'

const ActivityTimeWindowSchema = z.object({
  closeMin: z.boolean().optional(),
  closeMax: z.boolean().optional(),
  minDuration: z.number().optional(),
  maxDuration: z.number().optional(),
  uom: z.string().optional(),
  unit: z.string().optional(),
})

export const ExperienceFilterSchema = z.object({
  priceRange: z.string().optional(),
  pointRange: z.string().optional(),
  accessibilities: z.array(z.string()).optional(),
  specials: z.array(z.string()).optional(),
  sortBy: z.string().optional(),
  orderBy: z.string().optional(),
  timeWindow: z.string().optional(),
})

export const ExperienceSearchParamsSchema = z.object({
  searchType: z.string(),
  dates: z.object({
    startDateTime: z.string().min(1),
    endDateTime: z.string().min(1),
  }),
  destination: DestinationSchema,
  page: z
    .string()
    .default('1')
    .transform((val) => Number(val)),
  shoppingFilter: z
    .object({
      activityShoppingFilter: ExperienceFilterSchema.optional(),
    })
    .optional(),
  membership: MembershipSchema.optional(),
  prevQueryString: z.string().optional(),
  scrollRef: z.string().optional(),
})

export const ExperienceFormSchema = z.object({
  searchString: z.string().optional(),
  startDateTime: z.date(),
  endDateTime: z.date().nullable(),
  destination: DestinationSchema,
})

export type ExperienceSearchParams = z.infer<typeof ExperienceSearchParamsSchema>
export type ExperienceFilterQuery = z.infer<typeof ExperienceFilterSchema>
export type ExperienceForm = z.infer<typeof ExperienceFormSchema>
export type ActivityTimeWindow = z.infer<typeof ActivityTimeWindowSchema>
