import { useQueryStateToFormState } from '~/components/search-and-results/ExperienceResults/useQueryToFormState'
import {
  useDestinationState,
  useExperienceSearchForm,
} from '~/components/search-and-results/SearchExperienceForm/useExperienceSearch'
import { useUser } from '~/context/user'
import { usePartner } from '~/context/partner'
import { ExperienceSearchParams } from '@reward-platform/ancillaries-schemas/experience'
import { format } from 'date-fns'
import { useQueryParams } from '../useQueryParams/useQueryParams'

export function useExperiencePageData() {
  const { query, queryString } = useQueryParams<ExperienceSearchParams>()
  const defaultValues = useQueryStateToFormState(query)
  const { form } = useExperienceSearchForm(defaultValues)
  const destination = useDestinationState(form, defaultValues.destination)
  const dateFormat = 'y-MM-dd'
  const fromDate = format(defaultValues.startDateTime, dateFormat)
  const toDate = defaultValues.endDateTime ? format(defaultValues.endDateTime, dateFormat) : 'N/A'
  const user = useUser()
  const { code: partner } = usePartner()
  const userBalance = user.account?.balance.amount
  const bookingCurrency = user.userCurrency
  const memberIdentifier = user.user?.memberIdentifier?.identifier

  return {
    query,
    queryString,
    form,
    destination,
    fromDate,
    toDate,
    partner,
    userBalance,
    bookingCurrency,
    memberIdentifier,
  }
}
