import { z } from 'zod'
import { PriceLadderSchema } from '../../common'

export const ExperienceAvailabilityDataTransferObjectSchema = z.object({
  recommendationId: z.string(),
  ticketOptions: z
    .array(
      z.object({
        experienceDetailsTicketName: z.string().optional(), // * Note: title should be required, waiting for JRT
        experienceDescription: z.string(),
        timesAndPrices: z.array(
          z.object({
            cashPrice: z.number(),
            aviosPrice: z.number(),
            collectAmount: z.number(),
            pricingLadder: z.array(PriceLadderSchema).optional(),
            priceApplicabilityId: z.string(),
            tourStartTimes: z.array(z.string()).optional(),
          })
        ),
      })
    )
    .optional(),
})

export type ExperienceAvailabilityDataTransferObject = z.infer<
  typeof ExperienceAvailabilityDataTransferObjectSchema
>
