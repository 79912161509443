import { useRouter } from 'next/router'
import { parse } from 'qs'
import { useMemo } from 'react'

/**
 * Parses the current query string and unsafelycasts the result to the requested type.
 * Returns both the query string and the parsed query object.
 */
export function useQueryParams<TQueryParams>() {
  const { query, asPath } = useRouter()
  const queryString = asPath.slice(asPath.indexOf('?') + 1)
  const formattedQuery = useMemo<TQueryParams>(() => {
    const parsed = parse(query as Record<string, string>)
    return parsed as unknown as TQueryParams
  }, [query])

  return {
    queryString,
    query: formattedQuery,
  }
}
