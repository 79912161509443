import { z } from 'zod'

export const ExperienceGroupKeyEnumSchema = z.enum([
  'adult',
  'child',
  'infant',
  'youth',
  'senior',
  'traveler',
])

export const ExperienceGroupSchema = z.object({
  key: ExperienceGroupKeyEnumSchema,
  ageRange: z.array(z.number(), z.number()),
  minCount: z.number(),
  maxCount: z.number(),
})

export type ExperienceGroup = z.infer<typeof ExperienceGroupSchema>
export type ExperienceGroupKeyEnum = z.infer<typeof ExperienceGroupKeyEnumSchema>
